import linkIcon from './icons/simple_module-link.svg';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export default class UquendoModuleLink extends Plugin {
    init() {
        const editor = this.editor;

		editor.commands.add('insertUquendoModuleUrlCommmand',
		{
			execute: function () {
				DotNet.invokeMethodAsync('Rvl.KennisPortaal.Client', 'ShowModuleModal', editor.dotnet);
			}
		});
        editor.ui.componentFactory.add('insertUquendoModuleLink', locale => {
            const button = new ButtonView( locale );

            button.set( {
                id: `btninsertUquendoModuleLink_${editor.sourceElement.id}`,
                label: 'Link invoegen van een uQuendo module',
                icon: linkIcon,
                tooltip: true,
            } );

			this.listenTo(button, 'execute', () => {
				editor.commands.execute('insertUquendoModuleUrlCommmand');
			});

            return button;
        } );
    }
}